@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.slick-prev {
  left: 3% !important;
  z-index: 1;
}
.slick-next {
  right: 3% !important;
  z-index: 1;
}

.slick-track {
  display: flex;

  .slick-slide {
      height: auto;
      display: flex;

      > div {
          display: flex;
      }
  }
}

.slick-dots li button:before,
.slick-dots li.slick-active button:before {
  color: yellow !important;
}

.react-multiple-carousel__arrow{
  width: 4em;
  height: 4em;
}

.react-multiple-carousel__arrow::before{
  font-size: 40px !important;
}

.react-multiple-carousel__arrow {
  background-color: rgb(214, 214, 214, 0) !important;
}

/* .react-multiple-carousel__arrow:hover {
  background-color: yellow !important;
} */

.react-multiple-carousel__arrow::before {
  color: white !important;
}

.react-multiple-carousel__arrow:hover::before {
  color: yellow !important;
  transition: color 0.5s ease !important;
}
